<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="page-title">{{title}}</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Travelplaces",
  data() {
    return {
      title: "Travel Places"
    };
  }
};
</script>

<style>
</style>